export const LOGIN = "LOGIN";
export const USER_MANAGEMENT = "USER_MANAGEMENT";
export const ADMINDASHBOARD = "ADMINDASHBOARD";
export const LEAD = "LEAD";
export const LEAD_DETAIL = "LEAD_DETAIL";
export const BULK_UPLOAD = "BULK_UPLOAD";
export const BUlk = "BUlk";
export const ASSETS = "ASSETS";
export const WIDGETS = "WIDGETS";
export const TEAM = "TEAM";
export const DATA = "DATA";
export const SUPPORT = "SUPPORT";
export const SETTINGS = "SETTINGS";
export const FAQ = "FAQ";

export enum Modules {
  User = "User",
  Role = "Role",
  Team = "Team",
  Widget = "Widget",
  Lead = "Lead",
  LeadChatData = "LeadChatData",
  Setting = "Setting",
  MASTER_DATA = "Setting",
  Dashboard = "Dashboard",
  SocialApp = "SocialApp",
  Support = "Support",
  Workflow = "Workflow",
}

export enum UserType {
  Admin = "Admin",
  User = "User",
}

export const systemFields = [
  "name",
  "email",
  "phone",
  "addressLine1",
  "city",
  "state",
  "pincode",
  "dateOfBirth",
  "gender",
];

let SITE_URL = "";
let APP_URL = "";
let SSO_URL = "";
if (process.env.REACT_APP_ENV === "prod") {
  SITE_URL = "https://restthecase.com";
  APP_URL = "https://lms.restthecase.com";
  SSO_URL = "https://sso.restthecase.com";
} else if (process.env.REACT_APP_ENV === "uat") {
  SITE_URL = "https://uat.restthecase.com";
  APP_URL = "https://uat.lms.restthecase.com";
  SSO_URL = "https://uat.sso.restthecase.com";
} else {
  SITE_URL = "https://stage.restthecase.com";
  APP_URL = "https://dev.lms.restthecase.com";
  // APP_URL = "http://localhost:3000";
  SSO_URL = "https://stage.sso.restthecase.com";
}
export { SITE_URL, APP_URL, SSO_URL };
