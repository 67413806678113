import * as routePaths from "./RouteConstant";
import { Navigate, RouteObject } from "react-router-dom";
import React, { Suspense, useContext } from "react";
import { AppContext } from "./App";
import { Layout } from "./shared/Layout";
import { Loader } from "@lipihipi/rtc-lms-components";

const PageNotFound = React.lazy(() =>
  import("./pages/NotFound").then((module) => ({
    default: module.PageNotFound,
  })),
);
const Login = React.lazy(() =>
  import("./pages").then((module) => ({
    default: module.Login,
  })),
);

const AdminDashboard = React.lazy(() =>
  import("./pages/AdminDashboard").then((module) => ({
    default: module.AdminDashboard,
  })),
);
const LeadManagementPage = React.lazy(() =>
  import("./pages/LeadManagement").then((module) => ({
    default: module.ManageLeadPage,
  })),
);
const LeadDashboardPage = React.lazy(() =>
  import("./pages/LeadManagement").then((module) => ({
    default: module.LeadDashboardPage,
  })),
);
const TodayLeadPage = React.lazy(() =>
  import("./pages/LeadManagement").then((module) => ({
    default: module.TodayLeadPage,
  })),
);
const WidgetListPage = React.lazy(() =>
  import("./pages/Widgets").then((module) => ({
    default: module.WidgetListPage,
  })),
);
const WidgetDetailPage = React.lazy(() =>
  import("./pages/Widgets").then((module) => ({
    default: module.WidgetDetailPage,
  })),
);
const WidgetCreateEditPage = React.lazy(() =>
  import("./pages/Widgets").then((module) => ({
    default: module.WidgetCreateEditPage,
  })),
);
const SocialAppPage = React.lazy(() =>
  import("./pages/SocialApp").then((module) => ({
    default: module.SocialAppPage,
  })),
);
const ManageUserPage = React.lazy(() =>
  import("./pages/UserManagement").then((module) => ({
    default: module.ManageUserPage,
  })),
);
const ReportPage = React.lazy(() =>
  import("./pages/Report").then((module) => ({
    default: module.ReportPage,
  })),
);
const WorkflowPage = React.lazy(() =>
  import("./pages/Workflow").then((module) => ({
    default: module.WorkflowPage,
  })),
);
const AuthRoutes = ({ component }: any) => {
  const { state } = useContext<any>(AppContext);
  const isLoading = state?.app_loading;
 
  return (
    <>
      {!isLoading ? (
        <Layout>
          <Suspense fallback={<Loader />}>{component}</Suspense>
        </Layout>
      ) : null}
    </>
  );
};

// const PublicRoutes = ({ component }: any) => {
//   return <Suspense fallback={<Loader />}>{component}</Suspense>;
// };
export const routes: RouteObject[] = [
  {
    path: "/",
    children: [
      {
        path: routePaths.DASHBOARD,
        element: <AuthRoutes component={<AdminDashboard />} />,
      },
      {
        path: routePaths.USERS,
        element: <AuthRoutes component={<ManageUserPage />} />,
      },
      {
        path: routePaths.LEAD,
        element: <AuthRoutes component={<LeadManagementPage />} />,
      },
      {
        path: routePaths.LEAD_WITH_ID,
        element: <AuthRoutes component={<LeadManagementPage />} />,
      },
      {
        path: routePaths.LEAD_CALL_FEED,
        element: <AuthRoutes component={<LeadDashboardPage />} />,
      },
      {
        path: routePaths.TODAY_LEAD,
        element: <AuthRoutes component={<TodayLeadPage />} />,
      },
      {
        path: routePaths.WIDGETS,
        element: <AuthRoutes component={<WidgetListPage />} />,
      },
      {
        path: routePaths.SOCIAL_APP,
        element: <AuthRoutes component={<SocialAppPage />} />,
      },
      {
        path: routePaths.WIDGET_DETAIL,
        element: <AuthRoutes component={<WidgetDetailPage />} />,
      },
      {
        path: routePaths.WIDGET_CREATE,
        element: <AuthRoutes component={<WidgetCreateEditPage />} />,
      },
      {
        path: routePaths.REPORT,
        element: <AuthRoutes component={<ReportPage />} />,
      },
      {
        path: routePaths.WIDGET_EDIT,
        element: <AuthRoutes component={<WidgetCreateEditPage />} />,
      },
      {
        path: routePaths.WORKFLOW,
        element: <AuthRoutes component={<WorkflowPage />} />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <Suspense fallback={<Loader />}>
        <PageNotFound />
      </Suspense>
    ),
  },
];
