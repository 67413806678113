import { Modules } from "../../constant";
import { Icon } from "@lipihipi/lms-ui-components";
import * as routePaths from "../../RouteConstant";

const supportMenu = {
  id: "1",
  heading: "Help Center",
  data: [
    {
      id: "0.12",
      icon: Icon.Help,
      text: "Support",
      link: "/support",
      active: false,
    },
    {
      id: "1.2",
      icon: Icon.Faq,
      text: "FAQ’s",
      link: "/faq",
      active: false,
    },
  ],
};
export const MenuArr: any = [
  {
    id: "0",
    heading: "Menu",
    data: [
      {
        id: "0.1",
        icon: Icon.Dashboard,
        text: "Dashboard",
        isAdmin: true,
        active: true,
        child: [
          {
            id: "0.1.1",
            text: "Admin Dashboard",
            link: routePaths.DASHBOARD,
            active: true,
          },
        ],
      },
      {
        id: "0.2",
        icon: Icon.Lead,
        text: "Lead Management",
        isAdmin: false,
        active: true,
        child: [
          {
            id: "0.2.1",
            text: "Leads",
            link: routePaths.LEAD,
            module: Modules.Lead,
            active: true,
          },
          {
            id: "0.2.2",
            text: "Today's Lead",
            link: routePaths.TODAY_LEAD,
            active: true,
          },
          {
            id: "0.2.3",
            text: "Live Feed",
            link: routePaths.LEAD_CALL_FEED,
            active: true,
          },
          {
            id: "0.2.4",
            text: "Bulk Upload",
            link: routePaths.LEAD_BULK_UPLOAD,
            active: false,
          },
        ],
      },
      {
        id: "0.3",
        icon: Icon.Lead,
        text: "Report",
        active: true,
        link: routePaths.REPORT,
      },
      {
        id: "0.7",
        icon: Icon.Widget,
        text: "My Widgets",
        link: routePaths.WIDGETS,
        isAdmin: true,
        active: true,
      },
      {
        id: "0.16",
        icon: Icon.SocialApp,
        text: "Social App Management",
        link: routePaths.SOCIAL_APP,
        isAdmin: true,
        active: true,
      },
      {
        id: "0.17",
        icon: Icon.SocialApp,
        text: "Workflow Management",
        link: routePaths.WORKFLOW,
        active: true,
        modules: [Modules.Workflow],
      },
      {
        id: "0.17",
        icon: Icon.User,
        text: "User Management",
        link: routePaths.USERS,
        isAdmin: true,
        active: true,
      },
    ],
  },
  supportMenu,
];
