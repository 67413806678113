export const DASHBOARD = "/";
export const LOGIN = "/login";
export const LEAD = "/leads";
export const LEAD_WITH_ID = "/leads/:id";
export const LEAD_BULK_UPLOAD = "/lead/upload";
export const LEAD_CALL_FEED = "/lead/callings";
export const TODAY_LEAD = "/lead/today";
export const WIDGET_DETAIL = "/widgets/:id";
export const WIDGET_CREATE = "/widgets/create";
export const WIDGET_EDIT = "/widgets/edit/:id";
export const WIDGETS = "/widgets";
export const SETTINGS = "/settings";
export const FAQ = "/faq";
export const SOCIAL_APP = "/social-app";
export const USERS = "/users";
export const REPORT = "/reports";
export const WORKFLOW = "/workflow";
