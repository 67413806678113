import { ReactNode, useContext, useState } from "react";
import { Header } from "../Header";
import { Sidebar } from "../Sidebar";
import { ComponentWrapper } from "@lipihipi/lms-ui-components";
import { MenuArr } from "../Sidebar/constant";
import { AppContext } from "../../App";

interface IComponentWrapper {
  children: ReactNode;
}

const filterMenuByPermission = (user: any) => {
  let menu: any = MenuArr;
  if (user?.lmsPermissions?.userType !== "Supervisor") {
    menu[0].data = MenuArr[0].data.filter(
      (data: any) => data.isAdmin === false,
    );
  }
  return menu;
};

export const Layout: React.FC<IComponentWrapper> = ({ children }) => {
  const [open, setOpen] = useState(true);
  const { state } = useContext<any>(AppContext);
  let menu = filterMenuByPermission(state.user);

  return (
    <ComponentWrapper
      sidebar={<Sidebar toggle={open} setToggle={setOpen} menuArr={menu} />}
      header={<Header open={open} setOpen={setOpen} />}
    >
      {children}
    </ComponentWrapper>
  );
};
