import {
  useRoutes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { routes } from "./routes";
import { createContext, useContext, useEffect, useReducer } from "react";
import { ActionType, reducer } from "./Reducer";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import {
  ToastContainer,
  theme,
  handleToast,
} from "@lipihipi/lms-ui-components";
import LmsAPI from "@lipihipi/rtc-lms-client-sdk";
import { getNavigation } from "./shared/util";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";
import { APP_URL, SITE_URL, SSO_URL } from "./constant";

const firebaseConfig = {
  apiKey: "AIzaSyDdoKQ2VChUH8HvCoklmEDx-jsooPjObxs",
  authDomain: "rtcapp-99acb.firebaseapp.com",
  projectId: "rtcapp-99acb",
  storageBucket: "rtcapp-99acb.appspot.com",
  messagingSenderId: "439460531198",
  appId: "1:439460531198:web:13bd8c5bbe8f280dbdceaf",
  measurementId: "G-LX9BE9P5FK",
};

// const analytics = getAnalytics(app);

const initialState = {
  state: {
    app_loading: true,
    customTheme: {
      primaryColor: {
        first: "#000000",
        second: "#FED500",
      },
      secondaryColor: {
        first: "#BE0203",
        second: "#002F5F",
      },
    },
  },
  dispatch: () => {},
};

const NotifyBlock = ({ notification }: any) => {
  return (
    <div>
      <h4>{notification.title}</h4>
      <p>{notification.body}</p>
    </div>
  );
};

export const AppContext = createContext<{
  state: any;
  dispatch: any;
}>(initialState);

const Main = () => {
  const { dispatch } = useContext<any>(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ssoToken = searchParams.get("ssoToken");
  useEffect(() => {
    // auto login user if token exists
    const token = localStorage.getItem("token");
    if (token) {
      LmsAPI.setToken(token);
      LmsAPI.users
        .me()
        .then(({ data }) => {
          dispatch({
            type: ActionType.GET_USER_SUCCESS,
            payload: data.data,
          });

          dispatch({
            type: ActionType.SET_APP_LOADING_FLAG,
            payload: false,
          });

          if (location) {
            if (location.pathname !== "/") {
              navigate(location.pathname);
            } else {
              navigate(getNavigation(data.data));
            }
          }
        })
        .catch((err: any) => {
          dispatch({
            type: ActionType.SET_APP_LOADING_FLAG,
            payload: false,
          });
        });
    } else {
      if (ssoToken) {
        LmsAPI.auth
          .verifySso({ ssoToken: ssoToken})
          .then((res: any) => {
            LmsAPI.users.me().then(({ data }: any) => {
              dispatch({
                type: ActionType.GET_USER_SUCCESS,
                payload: data.data,
              });
              dispatch({
                type: ActionType.SET_APP_LOADING_FLAG,
                payload: false,
              });
              navigate(location.pathname);
            });
          })
          .catch((err: any) => {
            window.location.href = SITE_URL;
          });
      } else {
        window.location.href = `${SSO_URL}/login?serviceUrl=${APP_URL}`;
      }
    }
  }, []);

  const routing = useRoutes(routes);

  return <>{routing}</>;
};

const App = ({ config }: any) => {
  const [state, dispatch] = useReducer(reducer, initialState.state);
  const app = initializeApp(firebaseConfig);

  const messaging = getMessaging(app);
  useEffect(() => {
    Notification.requestPermission()
      .then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          getToken(messaging, {
            vapidKey:
              "BIVaoZefcCpA6DtooO5NByzh2-BiUY505kHP_Es2nvMO8njwbw76psvX--L6iV8CtuVyQVdZzK21QOWEeL1_dpw",
          })
            .then((resp) => {
              dispatch({
                type: ActionType.SET_FCM_TOKEN,
                payload: resp,
              });
            })
            .catch((err) => {
              console.log("Unable to get token", err);
            });
        } else {
          console.log("Notification permission", permission);
        }
      })
      .catch((err: any) => {
        console.log("Unable to get permission to notify.", err);
      });
  }, []);

  onMessage(messaging, (payload: any) => {
    console.log("Message received. ", payload);
    dispatch({
      type: ActionType.SET_NEW_NOTIFICATION,
      payload: payload,
    });
    handleToast(
      // @ts-ignore
      <NotifyBlock notification={payload.notification} />,
      "info",
    );
  });

  useEffect(() => {
    dispatch({
      type: ActionType.SET_TENANT,
      payload: config,
    });

    const { branding } = config;

    if (branding) {
      const customTheme: any = {};
      if (branding.primary1) {
        customTheme.primaryColor = {
          first: branding.primary1,
        };
      }
      if (branding.primary2) {
        customTheme.primaryColor = {
          ...customTheme.primaryColor,
          second: branding.primary2,
        };
      }
      if (branding.secondary1) {
        customTheme.secondaryColor = {
          first: branding.secondary1,
        };
      }
      if (branding.secondary2) {
        customTheme.secondaryColor = {
          ...customTheme.secondaryColor,
          second: branding.secondary2,
        };
      }
      dispatch({
        type: ActionType.SET_THEME,
        payload: customTheme,
      });
    }
  }, [config]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <ThemeProvider theme={createTheme(theme(state?.customTheme))}>
        <CssBaseline />
        <Main />
        <ToastContainer />
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
